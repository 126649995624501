<script>
  import Router from "svelte-spa-router";
  import Home from "./routes/Home.svelte";
  import Python34 from "./routes/Python34.svelte";
  import Python from "./routes/Python.svelte";
  import Ruby from "./routes/Ruby.svelte";
  import Golang from "./routes/Golang.svelte";
  import CPP from "./routes/CPP.svelte";
  import Julia from "./routes/Julia.svelte";
  import Perl from "./routes/Perl.svelte";
  import OCaml from "./routes/OCaml.svelte";
  import PHP from "./routes/PHP.svelte";

  const routes = {
    "/": Home,
    "/python/:id?": Python,
    "/python34/:id?": Python34,
    "/ruby/:id?": Ruby,
    "/golang/:id?": Golang,
    "/cpp/:id?": CPP,
    "/julia/:id?": Julia,
    "/perl/:id?": Perl,
    "/ocaml/:id?": OCaml,
    "/php/:id?": PHP,
  };
</script>

<Router {routes} />

<style lang="scss">
  @import "./theme.scss";

  :global(.CodeMirror) {
    text-align: left !important;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 16px;
    height: 45vh !important;
    @media (min-width: 576px) {
      height: 75vh !important;
    }
  }

  :global(.editor-row) {
    height: 75vh !important;
  }
  :global(.console) {
    background-color: lightslategrey;
    height: 100%;
    padding: 8px 20px;
    height: 45vh !important;
    @media (min-width: 576px) {
      height: 75vh !important;
    }
    overflow-y: scroll;
    overflow-x: hidden;
  }
  :global(.polylang-stdout),
  :global(.stderr),
  :global(.system) {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    font-size: 15px;
    color: white !important;
    white-space: break-spaces;
    word-wrap: break-word;
  }
</style>
